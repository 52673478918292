/*
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
*/


/* Global Component */

// videoSwiper - TVCF
import videoSwiper01  from '@/components/contents/videoSwiper01'
Vue.component(videoSwiper01.name, videoSwiper01)

// videoSwiper - 인포모셜 광고
import videoSwiper02  from '@/components/contents/videoSwiper02'
Vue.component(videoSwiper02.name, videoSwiper02)

// 전문가 네트워크
import networkTab  from '@/components/contents/networkTab'
Vue.component(networkTab.name, networkTab)

// 컨설팅 흐름
import roadMap  from '@/components/contents/roadMap'
Vue.component(roadMap.name, roadMap)

// 컨설팅 프로세스 - OTC
import processSwiper01  from '@/components/contents/processSwiper01'
Vue.component(processSwiper01.name, processSwiper01)

// 컨설팅 프로세스 - FA
import processSwiper02  from '@/components/contents/processSwiper02'
Vue.component(processSwiper02.name, processSwiper02)

// 업계 no.1 피플라이프 보험컨설팅
import bohumConsulting  from '@/components/contents/bohumConsulting'
Vue.component(bohumConsulting.name, bohumConsulting)

// 2020년 수상내역  
import award2020  from '@/components/contents/award2020'
Vue.component(award2020.name, award2020)

// 2020년 브러셔
import brochure2020  from '@/components/contents/brochure2020'
Vue.component(brochure2020.name, brochure2020)



/*
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
*/



/* Router */
import Vue          from 'vue';
import Router       from 'vue-router';

/* MAIN */
import main         from '@/views/main';

/* 피플라이프 */
import Company      from '@/views/intro/Company';
import History      from '@/views/intro/History';
import Network      from '@/views/intro/Network';
import Branch       from '@/views/intro/Branch';
import Social       from '@/views/intro/Social';
import Notify       from '@/views/intro/Notify';
import Finance      from '@/views/intro/Finance';

/* 영업채널 */
import Corporation  from '@/views/sales/Corporation';
import Fa           from '@/views/sales/Fa';
import Efa          from '@/views/sales/Efa';
import Tfa          from '@/views/sales/Tfa';
import Otc          from '@/views/sales/Otc';

/* 브랜드 */
import Bohum        from '@/views/brand/Bohum';
import Ceo          from '@/views/brand/Ceo';

/* 홍보센터 */
import News         from '@/views/promotion/News';
import Inside       from '@/views/promotion/Inside';
import Webzine      from '@/views/promotion/Webzine';
import Promotion    from '@/views/promotion/Promotion';

/* 인재채용 */
import RecruitInfo from '@/views/recruit/RecruitInfo';
import RecruitCorp from '@/views/recruit/RecruitCorp';
import RecruitFa from '@/views/recruit/RecruitFa';
import RecruitBohum from '@/views/recruit/RecruitBohum';
import RecruitEfa from '@/views/recruit/RecruitEfa';
import RecruitSpecial from '@/views/recruit/RecruitSpecial';
import RecruitComplete from '@/views/recruit/RecruitComplete';

/* 게시판 */
import BoardView from '@/views/board/View';

/* COMMON */
import Privacy      from '@/views/etc/Privacy';
import CorpComplete from '@/views/etc/CorpComplete';
import IndiComplete from '@/views/etc/IndiComplete';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        //404
        // {
        //     path: '*',
        //     redirect: '/404'
        // },
        { /* MAIN */
            path: '/',
            name: main,
            component: main
        },{
            path: '/index.html',
            name: main,
            component: main
        },{ /* 피플라이프 */
            path: '/intro/Company',
            name: Company,
            component: Company
        },{
            path: '/intro/History',
            name: History,
            component: History
        },{
            path: '/intro/Network',
            name: Network,
            component: Network
        },{
            path: '/intro/Branch',
            name: Branch,
            component: Branch
        },{
            path: '/intro/Social',
            name: Social,
            component: Social
        },{
            path: '/intro/Notify',
            name: Notify,
            component: Notify
        },{
            path: '/intro/Finance',
            name: Finance,
            component: Finance
        },{ /* 영업채널 */
            path: '/sales/Corporation',
            name: Corporation,
            component: Corporation
        },{
            path: '/sales/Fa',
            name: Fa,
            component: Fa
        },{
            path: '/sales/Otc',
            name: Otc,
            component: Otc
        },{
            path: '/sales/Efa',
            name: Efa,
            component: Efa
        },{
            path: '/sales/Tfa',
            name: Tfa,
            component: Tfa
        },{ /* 브랜드 */
            path: '/brand/Bohum',
            name: Bohum,
            component: Bohum
        },{
            path: '/brand/Ceo',
            name: Ceo,
            component: Ceo
        },{ /* 홍보센터 */
            path: '/promotion/News',
            name: News,
            component: News
        },        
        {
            path: '/promotion/Inside',
            name: Inside,
            component: Inside
        },{
            path: '/promotion/Webzine',
            name: Webzine,
            component: Webzine
        },{
            path: '/promotion/Promotion',
            name: Promotion,
            component: Promotion
        },{ /* 인재채용 */
            path: '/recruit/RecruitInfo',
            name: RecruitInfo,
            component: RecruitInfo
        },{
            path: '/recruit/RecruitCorp',
            name: RecruitCorp,
            component: RecruitCorp
        },{
            path: '/recruit/RecruitFa',
            name: RecruitFa,
            component: RecruitFa
        },{
            path: '/recruit/RecruitBohum',
            name: RecruitBohum,
            component: RecruitBohum
        },{
            path: '/recruit/RecruitEfa',
            name: RecruitEfa,
            component: RecruitEfa
        },{
            path: '/recruit/RecruitSpecial',
            name: RecruitSpecial,
            component: RecruitSpecial
        },{
            path: '/recruit/RecruitComplete',
            name: RecruitComplete,
            component: RecruitComplete
        },{ /* 게시판 */
            path: '/board/View',
            name: BoardView,
            component: BoardView
        },{ /* COMMON */
            path: '/Privacy',
            name: Privacy,
            component: Privacy
        },{
            path: '/CorpComplete',
            name: CorpComplete,
            component: CorpComplete
        },{
            path: '/IndiComplete',
            name: IndiComplete,
            component: IndiComplete
        }
    ]
})

export default router



/*
■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
*/